import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderByName } from '../communication-workflow-target-order-by-name';

import { CommunicationWorkflowTargetOrderByConstructor } from './communication-workflow-target-order-by-constructor';

export abstract class CommunicationWorkflowTargetOrderBy extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: CommunicationWorkflowTargetOrderByName;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetOrderByConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationWorkflowTargetOrderBy');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType() {
    return cloneDeep(this.type);
  }
}
