import { DBDocSchema } from "../../generic/db-doc/db-doc-schema";
import { SchemaField } from "../../generic/serialization/schema-field";
import { CommunicationDevices } from "../../communication/communication-device/communication-devices/communication-devices";

export class UserPrivateDataSchema extends DBDocSchema {
  public static readonly e164Phone = "e164Phone";

  public static readonly awayFromPhoneStartTime = "awayFromPhoneStartTime";

  public static readonly devices = "devices";

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = "userPrivate";

    public static readonly hoursAwayFromPhone = 24;

    public static readonly USLocalPhoneNumDigits = 10;
  };

  static Defaults = class {
    public static readonly e164Phone = "";

    public static readonly awayFromPhoneStartTime = undefined;

    public static readonly devices = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([UserPrivateDataSchema.e164Phone]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import("joi").AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[UserPrivateDataSchema.e164Phone] = SchemaField.string(
      UserPrivateDataSchema.Defaults.e164Phone
    );
    parameters[UserPrivateDataSchema.awayFromPhoneStartTime] =
      SchemaField.timestamp(
        UserPrivateDataSchema.Defaults.awayFromPhoneStartTime
      );
    parameters[UserPrivateDataSchema.devices] = SchemaField.obj(
      CommunicationDevices,
      UserPrivateDataSchema.Defaults.devices
    );

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${
      UserPrivateDataSchema.Constants.collection
    }`;
  }
}
