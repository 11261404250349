import { SchemaField } from '../../../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderByName } from '../communication-workflow-target-order-by-name';

export class CommunicationWorkflowTargetOrderBySchema extends SerializableObjectSchema {
  public static readonly type = 'type';

  public static readonly Defaults = class {
    public static readonly type = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetOrderBySchema.type] = SchemaField.string(
      CommunicationWorkflowTargetOrderBySchema.Defaults.type,
    )
      .required()
      .valid(...Object.values(CommunicationWorkflowTargetOrderByName));
    /* eslint-enable */

    return parameters;
  }
}
