import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetSchema } from '../communication-workflow-target/communication-workflow-target/communication-workflow-target-schema';
import { AnyCommunicationWorkflowTargetOrderBy } from '../communication-workflow-target/communication-workflow-target-order-by/generic/any-communication-workflow-target-order-by/any-communication-workflow-target-order-by';

export class CommunicationWorkflowDefaultFilterSchema extends SerializableObjectSchema {
  public static readonly orderBy = 'orderBy';

  public static readonly queueId = 'queueId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly orderBy = undefined;

    public static readonly queueId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetSchema.orderBy] = SchemaField.obj(
      AnyCommunicationWorkflowTargetOrderBy,
      CommunicationWorkflowTargetSchema.Defaults.orderBy,
    ).optional();
    parameters[CommunicationWorkflowDefaultFilterSchema.queueId] = SchemaField.autoGeneratedDocId(
      CommunicationWorkflowDefaultFilterSchema.Defaults.queueId,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
