/**
 * List all possible commands the system is capable of processing.
 */
export enum CommunicationTaskName {
  call = 'call',
  callbackRequest = 'callbackRequest',
  conversationRequest = 'conversationRequest',
}

export type CommunicationTaskNameType = keyof typeof CommunicationTaskName;
