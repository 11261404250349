///////////////////////////////////////////////////////////////////////
// HTTP Parameters: Voice Webhook
///////////////////////////////////////////////////////////////////////

import { PwpVoiceWebhookInboundSession } from './pwp-voice-webhook-inbound-session';

export class SoftDeviceWebhookParameters {
  public static readonly Common = {
    ...PwpVoiceWebhookInboundSession,
    softDeviceAction: 'softDeviceAction',
  };

  public static readonly JoinOngoingConference = {
    ...SoftDeviceWebhookParameters.Common,
  };
}
