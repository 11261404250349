export enum CommunicationWorkflowTargetOrderByName {
  /**
   * Order by longest idle time
   **/
  longestIdleTime = "longestIdleTime",
  /**
   * Order by explicit order
   **/
  explicit = "explicit",
}

export type CommunicationWorkflowTargetOrderByNameType = keyof typeof CommunicationWorkflowTargetOrderByName;
