import { cloneDeep } from "lodash";
import moment from "moment-timezone";

import { GLOBAL_TIMEZONE } from "../../../helper/constants";
import { Interval } from "../../../helper/interval";
import { displayTime } from "../../../helper/time/display-time/display-time";
import { DBDocObject } from "../../generic/db-doc/db-doc-object";
import { DBDocSchema } from "../../generic/db-doc/db-doc-schema";
import { isNilOrDefault } from "../../generic/serialization/is-nil-or-default";

import { AssignedUserType } from "./enums";
import { EventDataConstructor } from "./event-data-constructor";
import { EventDataSchema } from "./event-data-schema";

export class EventData extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected start!: moment.Moment;

  protected end!: moment.Moment;

  protected type!: string;

  protected assignedUserId?: string;

  protected assignedBackupUserId?: string;

  protected color?: string;

  protected eventConfigId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventDataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck() {
    if (this.end.isSameOrBefore(this.start)) {
      console.error(
        "Attempted to serialize an event with start time same or before end time: ",
        {
          start: displayTime(this.start, GLOBAL_TIMEZONE, { timeOnly: false }),
          end: displayTime(this.end, GLOBAL_TIMEZONE, { timeOnly: false }),
        }
      );
      throw new Error("Invalid event.");
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import("joi").ValidationResult
  ): EventData {
    return new EventData(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventData.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  private static _schema = EventData.getSchema();

  public static getSchema(): DBDocSchema {
    return new EventDataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Static Methods
  /////////////////////////////////////////////////////////////////////////////

  public static isNilOrDefaultUser(userId: string | undefined): boolean {
    return isNilOrDefault(
      userId,
      EventDataSchema.assignedUserId,
      EventData._schema
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getStart(): moment.Moment {
    return cloneDeep(this.start);
  }

  public getEnd(): moment.Moment {
    return cloneDeep(this.end);
  }

  public getType(): string {
    return cloneDeep(this.type);
  }

  public getAssignedUserId(): string | undefined {
    return cloneDeep(this.assignedUserId);
  }

  public getAssignedBackupUserId(): string | undefined {
    return cloneDeep(this.assignedBackupUserId);
  }

  public getColor(): string | undefined {
    return cloneDeep(this.color);
  }

  public getEventConfigId(): string {
    return cloneDeep(this.eventConfigId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setStart(start: moment.Moment): EventData {
    this.start = start;
    return this;
  }

  public setEnd(end: moment.Moment): EventData {
    this.end = end;
    return this;
  }

  public setAssignedUserId(assignedUserId: string | undefined): EventData {
    this.assignedUserId = assignedUserId;
    return this;
  }

  public setAssignedBackupUserId(
    assignedBackupUserId: string | undefined
  ): EventData {
    this.assignedBackupUserId = assignedBackupUserId;
    return this;
  }

  public setColor(color: string | undefined): EventData {
    this.color = color;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine if the event may be requested by a non-privileged
   * org member.
   */
  public isRequestable(): boolean {
    // Colored events are not requestable
    if (this.color === EventDataSchema.Colors.reserved) {
      return false;
    }

    return (
      EventData.isNilOrDefaultUser(this.assignedUserId) ||
      EventData.isNilOrDefaultUser(this.assignedBackupUserId)
    );
  }

  /**
   * Return the duration of this event, in milliseconds.
   */
  public durationMs(): number {
    return this.getEnd().valueOf() - this.getStart().valueOf();
  }

  /**
   * Determine if this event is simply the given event, shifted by some unknown number of weeks,
   * either in the future or past.
   *
   * @param otherEventData
   */
  public isShiftedByWeek(otherEventData: EventData) {
    if (otherEventData.getType() !== this.getType()) {
      return false;
    }

    // Check each of the following properties!
    const checks = [
      "dddd", // Same day of week
      "h", // 12 hour-hour
      "a", // AM-PM
      "mm", // Minute
      "ss", // Second
    ];

    for (const checkString of checks) {
      if (
        otherEventData.getStart().format(checkString) !==
        this.getStart().format(checkString)
      ) {
        return false;
      }

      if (
        otherEventData.getEnd().format(checkString) !==
        this.getEnd().format(checkString)
      ) {
        return false;
      }
    }

    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Comparison
  /////////////////////////////////////////////////////////////////////////////

  /**
   *
   * @returns The interval specifying this event in milliseconds
   */
  public getIntervalMs(): Interval {
    return new Interval(this.start.valueOf(), this.end.valueOf());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Overlap
  /////////////////////////////////////////////////////////////////////////////

  public includesNow(): boolean {
    return this.getIntervalMs().contains(moment());
  }

  public overlaps(otherEvent: EventData): boolean {
    return this.overlapsAnyOf([otherEvent]);
  }

  public overlapsAnyOf(otherEvents: EventData[]): boolean {
    const interval = this.getIntervalMs();
    for (const otherEvent of otherEvents) {
      if (otherEvent.getIntervalMs().overlaps(interval)) {
        return true;
      }
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Users
  /////////////////////////////////////////////////////////////////////////////

  public getUser(assignedUserType: AssignedUserType): string | undefined {
    switch (assignedUserType) {
      case AssignedUserType.primary:
        return this.getAssignedUserId();
      case AssignedUserType.backup:
        return this.getAssignedBackupUserId();
      default:
        throw new Error(
          `User Error: EventData.getUser, unknown user type: '${assignedUserType}'`
        );
    }
  }

  /**
   * Return the array of users assigned to this event. Return the empty list
   * if no user is assigned.
   *
   * @param assignedUserTypes If specified, order the results according to the
   * specified array. If this array contains duplicates, we duplicate
   * the corresponding users in the result.
   */
  public getUsers(assignedUserTypes: AssignedUserType[]): string[] {
    const result: string[] = [];
    for (const assignedUserType of assignedUserTypes) {
      switch (assignedUserType) {
        case AssignedUserType.primary: {
          if (!EventData.isNilOrDefaultUser(this.assignedUserId)) {
            result.push(this.getAssignedUserId()!);
          }
          continue;
        }
        case AssignedUserType.backup: {
          if (!EventData.isNilOrDefaultUser(this.assignedBackupUserId)) {
            result.push(this.getAssignedBackupUserId()!);
          }
          continue;
        }
        default: {
          console.error(
            `EventData.getUsers: Unknown AssignedUserType='${assignedUserType}'`
          );
          throw new Error("EventData.getUsers: Cannot get users");
        }
      }
    }
    return result;
  }
}
