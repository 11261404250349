import { cloneDeep } from 'lodash';

import { CalleeEntity } from '../../../../../objects/call/callee-entity/callee-entity';
import { TimeSelection } from '../../../../../objects/core/time-selection/time-selection/time-selection';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { IdentityConfigConversation } from '../../../../../objects/identity/identity-config/identity-config-conversation/identity-config-conversation';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { ConversationRequestName } from '../../../conversation-request-name';
import { ConversationRequest } from '../../generic/conversation-request/conversation-request';
import { ConversationRequestSchema } from '../../generic/conversation-request/conversation-request-schema';

import { ConversationRequestConfigureConstructor } from './conversation-request-configure-constructor';
import { ConversationRequestConfigureSchema } from './conversation-request-configure-schema';

export class ConversationRequestConfigure extends ConversationRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected conversationConfigId!: string;

  protected availableTimes: TimeSelection | undefined;

  protected redirectURL: string | undefined;

  protected inactiveAfterDuration!: string;

  protected closedAfterDuration!: string;

  protected communicationWorkflowId?: string;

  protected pIIRetentionDuration!: string;

  protected waitingRoomConfigEnabled!: boolean;

  protected identityConfig?: IdentityConfigConversation;

  protected serviceProviders?: CalleeEntity[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationRequestConfigureConstructor) {
    super({
      ...parameters,
      [ConversationRequestSchema.type]: ConversationRequestName.configure,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationRequestConfigure {
    return new ConversationRequestConfigure(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationRequestConfigure.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationRequestConfigureSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getConversationConfigId() {
    return cloneDeep(this.conversationConfigId);
  }

  public getAvailableTimes() {
    return cloneDeep(this.availableTimes);
  }

  public getInactiveAfterDuration() {
    return cloneDeep(this.inactiveAfterDuration);
  }

  public getClosedAfterDuration() {
    return cloneDeep(this.closedAfterDuration);
  }

  public getPIIRetentionDuration() {
    return cloneDeep(this.pIIRetentionDuration);
  }

  public getCommunicationWorkflowId() {
    return cloneDeep(this.communicationWorkflowId);
  }

  public getRedirectURL() {
    return cloneDeep(this.redirectURL);
  }

  public getWaitingRoomConfigEnabled() {
    return cloneDeep(this.waitingRoomConfigEnabled);
  }

  public getIdentityConfig() {
    return cloneDeep(this.identityConfig);
  }

  public getServiceProviders() {
    return cloneDeep(this.serviceProviders);
  }
}
