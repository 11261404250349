<ng-container *transloco="let t; read: 'org-settings-page'">
  <p-tabView class="p-tab-view--full-height">
    <!-- Call Types Tab-->
    <p-tabPanel [header]="t('callList')">
      <app-org-settings-call-list></app-org-settings-call-list>
    </p-tabPanel>
    <!-- Communication Workflows Tab-->
    <p-tabPanel [header]="t('communicationWorkflowLabel')">
      <app-org-settings-communication-workflow></app-org-settings-communication-workflow>
    </p-tabPanel>
    <!-- Shift Request Config Tab-->
    <p-tabPanel [header]="t('eventRequestConfig')">
      <app-org-settings-event-request-config></app-org-settings-event-request-config>
    </p-tabPanel>
    <!-- Service Limit Settings Tab-->
    <p-tabPanel [header]="t('enforceServiceLimit')">
      <app-org-settings-enforce-service-limit></app-org-settings-enforce-service-limit>
    </p-tabPanel>
    <p-tabPanel *ngIf="isTestingOrg$ | async">
      <ng-template pTemplate="header">
        <div class="org-settings__tab-header">
          {{ t('formSettings') }}
        </div>
      </ng-template>
      <app-form-table-editor></app-form-table-editor>
    </p-tabPanel>
    <p-tabPanel [header]="t('automations')">
      <app-automation-table-editor></app-automation-table-editor>
    </p-tabPanel>
    <p-tabPanel [header]="t('phoneLines')">
      <app-phone-line-table-editor></app-phone-line-table-editor>
    </p-tabPanel>
    <p-tabPanel *ngIf="conversationConfigExists" [header]="t('chatSettings')">
      <app-conversation-settings></app-conversation-settings>
    </p-tabPanel>
  </p-tabView>
</ng-container>
