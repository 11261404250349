import { cloneDeep } from "lodash";
import moment from "moment-timezone";

import { GLOBAL_TIMEZONE } from "../../../helper/constants";
import { usLocalPhone } from "../../../helper/phone-utils/us-local-phone";
import { timeRangeContains } from "../../../helper/time-range";
import { DBDocObject } from "../../generic/db-doc/db-doc-object";
import { DBDocSchema } from "../../generic/db-doc/db-doc-schema";
import { CommunicationDevices } from "../../communication/communication-device/communication-devices/communication-devices";

import { UserPrivateDataConstructor } from "./user-private-data-constructor";
import { UserPrivateDataSchema } from "./user-private-data-schema";

export class UserPrivateData extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected e164Phone!: string;

  protected awayFromPhoneStartTime: moment.Moment | undefined;

  protected devices: CommunicationDevices | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: UserPrivateDataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new UserPrivateDataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import("joi").ValidationResult
  ): UserPrivateData {
    return new UserPrivateData(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * The e164Phone property is private to mitigate IRSF attacks. Only the US Local
   * phone is visible.
   */
  public getUSLocalPhone(): string | undefined {
    return usLocalPhone(this.e164Phone);
  }

  /**
   * Return time starting time when the user requested to be unavailable for 24H.
   * If missing, then the user has not made such a request, and is considered available.
   *
   * If missing, return undefined.
   */
  public getAwayFromPhoneStartTime(): moment.Moment | undefined {
    return cloneDeep(this.awayFromPhoneStartTime);
  }

  /**
   * Return the communication devices associated with this user.
   * If missing, return undefined.
   */
  public getDevices(): CommunicationDevices | undefined {
    return cloneDeep(this.devices);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setE164Phone(e164Phone: string): UserPrivateData {
    this.e164Phone = e164Phone;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserPrivateData.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Away From Phone Functions
  /////////////////////////////////////////////////////////////////////////////

  /**
   * See unavailableStartTime.
   */
  public getAwayFromPhoneEndTime(): moment.Moment | undefined {
    const startTime = cloneDeep(this.getAwayFromPhoneStartTime());
    if (startTime === undefined) {
      return undefined;
    }

    startTime.add(UserPrivateDataSchema.Constants.hoursAwayFromPhone, "hours");
    return startTime;
  }

  /**
   * User can mark self as away from their phone for 24H. This function determines if
   * they are away from their phone now.
   *
   * The timestamp of user's request
   * is saved in firebstore. We check if now \in [requestTimestamp, requestTimestamp+24h].
   */
  public isAwayFromPhone(): boolean {
    if (this.getAwayFromPhoneStartTime() === undefined) {
      return false;
    }

    return timeRangeContains(
      {
        start: this.getAwayFromPhoneStartTime()!,
        end: this.getAwayFromPhoneEndTime()!,
      },
      moment.tz(undefined, GLOBAL_TIMEZONE)
    );
  }
}
