import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderBy } from '../../generic/communication-workflow-target-order-by/communication-workflow-target-order-by';
import { CommunicationWorkflowTargetOrderByName } from '../../generic/communication-workflow-target-order-by-name';

import { CommunicationWorkflowTargetOrderByExplicitConstructor } from './communication-workflow-target-order-by-explicit-constructor';
import { CommunicationWorkflowTargetOrderByExplicitSchema } from './communication-workflow-target-order-by-explicit-schema';

export class CommunicationWorkflowTargetOrderByExplicit extends CommunicationWorkflowTargetOrderBy {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetOrderByExplicitConstructor) {
    super({
      ...parameters,
      type: CommunicationWorkflowTargetOrderByName.explicit,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationWorkflowTargetOrderByExplicit {
    return new CommunicationWorkflowTargetOrderByExplicit(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowTargetOrderByExplicit.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowTargetOrderByExplicitSchema();
  }
}
