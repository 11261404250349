import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationDeviceNameType } from './communication-device-name';

import { CommunicationDeviceConstructor } from './communication-device-constructor';
import { CommunicationDeviceSchema } from './communication-device-schema';

export class CommunicationDevice extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: CommunicationDeviceNameType;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationDeviceConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationDevice {
    return new CommunicationDevice(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationDevice.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationDeviceSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CommunicationDeviceNameType {
    return cloneDeep(this.type);
  }
}
