import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderBy } from '../../generic/communication-workflow-target-order-by/communication-workflow-target-order-by';
import { CommunicationWorkflowTargetOrderByName } from '../../generic/communication-workflow-target-order-by-name';

import { CommunicationWorkflowTargetOrderByLongestIdleConstructor } from './communication-workflow-target-order-by-longest-idle-constructor';
import { CommunicationWorkflowTargetOrderByLongestIdleSchema } from './communication-workflow-target-order-by-longest-idle-schema';

export class CommunicationWorkflowTargetOrderByLongestIdle extends CommunicationWorkflowTargetOrderBy {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetOrderByLongestIdleConstructor) {
    super({
      ...parameters,
      type: CommunicationWorkflowTargetOrderByName.longestIdleTime,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is protected, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationWorkflowTargetOrderByLongestIdle {
    return new CommunicationWorkflowTargetOrderByLongestIdle(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowTargetOrderByLongestIdle.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowTargetOrderByLongestIdleSchema();
  }
}
