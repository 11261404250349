import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationDevice } from '../communication-device/communication-device';

import { CommunicationDevicesConstructor } from './communication-devices-constructor';
import { CommunicationDevicesSchema } from './communication-devices-schema';

export class CommunicationDevices extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected devices!: CommunicationDevice[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationDevicesConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationDevices {
    return new CommunicationDevices(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationDevices.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationDevicesSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDevices(): CommunicationDevice[] {
    return cloneDeep(this.devices);
  }
}
