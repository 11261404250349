import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class CommunicationTaskQueuePermissionsSchema extends SerializableObjectSchema {
  public static readonly join = 'join';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static Defaults = class {
    public static readonly join = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationTaskQueuePermissionsSchema.join] = SchemaField.boolean(
      CommunicationTaskQueuePermissionsSchema.Defaults.join,
    );
    /* eslint-enable */

    return parameters;
  }
}
