import { AnySerializableObject } from '../../../../../../generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../../../generic/serialization/interfaces';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderByExplicit } from '../../type/communication-workflow-target-order-by-explicit/communication-workflow-target-order-by-explicit';
import { CommunicationWorkflowTargetOrderByLongestIdle } from '../../type/communication-workflow-target-order-by-longest-idle/communication-workflow-target-order-by-longest-idle';
import { CommunicationWorkflowTargetOrderByName } from '../communication-workflow-target-order-by-name';

import { AnyCommunicationWorkflowTargetOrderBySchema } from './any-communication-workflow-target-order-by-schema';

export abstract class AnyCommunicationWorkflowTargetOrderBy extends AnySerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Get the object builder for the given validation result
   *
   * @param validationResult
   * @returns
   */
  protected static getObjectBuilder(validationResult: import('joi').ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyCommunicationWorkflowTargetOrderBySchema.type];
    const type =
      CommunicationWorkflowTargetOrderByName[typeString as keyof typeof CommunicationWorkflowTargetOrderByName];

    if (type === undefined) {
      throw new Error(
        `AnyCommunicationWorkflowTargetOrderBy._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }
    switch (type) {
      case CommunicationWorkflowTargetOrderByName.explicit:
        return CommunicationWorkflowTargetOrderByExplicit;
      case CommunicationWorkflowTargetOrderByName.longestIdleTime:
        return CommunicationWorkflowTargetOrderByLongestIdle;
      default:
        throw new Error(`AnyCommunicationWorkflowTargetOrderBy.getObjectBuilder: Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyCommunicationWorkflowTargetOrderBySchema();
  }
}
