import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { AnyCommunicationWorkflowTargetOrderBy } from '../communication-workflow-target-order-by/generic/any-communication-workflow-target-order-by/any-communication-workflow-target-order-by';
import { AnyCommunicationWorkflowTargetTimeout } from '../communication-workflow-target-timeout/generic/any-communication-workflow-target-timeout/any-communication-workflow-target-timeout';

export class CommunicationWorkflowTargetSchema extends SerializableObjectSchema {
  public static readonly orderBy = 'orderBy';

  public static readonly queueId = 'queueId';

  public static readonly timeout = 'timeout';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly orderBy = undefined;

    public static readonly queueId = undefined;

    public static readonly timeout = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetSchema.orderBy] = SchemaField.obj(
      AnyCommunicationWorkflowTargetOrderBy,
      CommunicationWorkflowTargetSchema.Defaults.orderBy,
    ).optional();
    parameters[CommunicationWorkflowTargetSchema.queueId] = SchemaField.autoGeneratedDocId(
      CommunicationWorkflowTargetSchema.Defaults.queueId,
    ).optional();
    parameters[CommunicationWorkflowTargetSchema.timeout] = SchemaField.obj(
      AnyCommunicationWorkflowTargetTimeout,
      CommunicationWorkflowTargetSchema.Defaults.timeout,
    ).optional();
    /* eslint-enable */

    return parameters;
  }
}
