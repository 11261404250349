import { SchemaField } from "../../../../generic/serialization/schema-field";
import { SerializableObjectSchema } from "../../../../generic/serialization/serializable-object-schema";

export class CallListCommunicationWorkflowConfigSchema extends SerializableObjectSchema {
  public static readonly communicationWorkflowId = "communicationWorkflowId";

  static Defaults = class {
    public static readonly communicationWorkflowId = undefined;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import("joi").AnySchema } {
    const parameters = {} as any;
    parameters[CallListCommunicationWorkflowConfigSchema.communicationWorkflowId] = SchemaField.autoGeneratedDocId(
      CallListCommunicationWorkflowConfigSchema.Defaults.communicationWorkflowId
    );
    return parameters;
  }
}
