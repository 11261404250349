import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderBy } from '../communication-workflow-target-order-by/generic/communication-workflow-target-order-by/communication-workflow-target-order-by';
import { CommunicationWorkflowTargetTimeout } from '../communication-workflow-target-timeout/generic/communication-workflow-target-timeout/communication-workflow-target-timeout';

import { CommunicationWorkflowTargetConstructor } from './communication-workflow-target-constructor';
import { CommunicationWorkflowTargetSchema } from './communication-workflow-target-schema';

export class CommunicationWorkflowTarget extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected orderBy!: CommunicationWorkflowTargetOrderBy | undefined;

  protected queueId!: string | undefined;

  protected timeout!: CommunicationWorkflowTargetTimeout | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflowTarget {
    return new CommunicationWorkflowTarget(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowTarget.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowTargetSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOrderBy() {
    return cloneDeep(this.orderBy);
  }

  public getQueueId() {
    return cloneDeep(this.queueId);
  }

  public getTimeout() {
    return cloneDeep(this.timeout);
  }
}
