import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

import { ConversationConfigService } from '../../services/conversation/conversation-config/conversation-config.service';
import { OrgDataService } from '../../services/orgs/org-data/org-data.service';

@UntilDestroy()
@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsComponent implements OnInit {
  private readonly orgDataService = inject(OrgDataService);

  private readonly conversationConfigService = inject(ConversationConfigService);

  public conversationConfigExists: boolean = false;

  public readonly isTestingOrg$ = this.orgDataService.getOrgData().pipe(
    map((orgData) =>
      [
        'S6E04dqIvOg0ljfAeLgr',
        // Peace over Violence
        '7CVagR51BeLVRQmidONP',
      ].includes(orgData?.getId()),
    ),
  );

  public ngOnInit() {
    this.conversationConfigService
      .getUniqueDoc({ query: [] })
      .pipe(
        map((config) => {
          this.conversationConfigExists = !!config;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
