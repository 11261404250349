<ng-container *transloco="let t; read: 'change-other-user-email'">
  <ng-template #objEditor>
    <p-messages severity="info">
      <ng-template pTemplate>
        <p class="wrap">{{ t('instructions') }}</p>
      </ng-template>
    </p-messages>

    <form class="change-other-user-email__form">
      <fieldset class="change-other-user-email__form-fields">
        <label *ngrxLet="form.controls.newEmail as newEmailControl" class="change-other-user-email__form-field">
          <span class="change-other-user-email__form-field-label">{{ t('newEmailField.label') }}</span>
          <input
            pInputText
            styleClass="p-email--full-width"
            [formControl]="newEmailControl"
            [placeholder]="t('newEmailField.placeholder')"
          >
        </label>
        <label
          *ngrxLet="form.controls.newEmailConfirmation as newEmailConfirmationControl"
          class="change-other-user-email__form-field"
        >
          <span class="change-other-user-email__form-field-label">{{ t('newEmailConfirmationField.label') }}</span>
          <input
            pInputText
            styleClass="p-email--full-width"
            [formControl]="newEmailConfirmationControl"
            [class.ng-invalid]="newEmailConfirmationControl.touched && form.invalid"
            [placeholder]="t('newEmailConfirmationField.placeholder')"
          >
          <app-form-field-help [control]="form">
            <ng-container
              *ngIf="newEmailConfirmationControl.touched && form.errors as formErrors"
              form-field-help-errors
            >
              <div *ngIf="formErrors.mustMatch">
                {{ t('newEmailConfirmationField.mustMatchError') }}
              </div>
            </ng-container>
          </app-form-field-help>
        </label>

        <p-messages class="p-messages--no-margin" [closable]="false"></p-messages>
      </fieldset>
    </form>
  </ng-template>

  <app-settings
    [auditEntry]="undefined"
    [cardTitle]="t('title')"
    [doUpload]="changeEmail"
    [editorTemplate]="objEditor"
    [resetForm]="resetForm"
    [valid]="form?.valid ?? false"
  >
  </app-settings>
</ng-container>
