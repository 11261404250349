import { animate, state, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animation for row expansion in data tables.
 * This animation provides a smooth transition when expanding or collapsing rows.
 */
export function getRowExpansionAnimation(): AnimationTriggerMetadata {
  return trigger('rowExpansionTrigger', [
    state(
      'void',
      style({
        transform: 'translateX(-10%)',
        opacity: 0,
      }),
    ),
    state(
      'active',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      }),
    ),
    transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
  ]);
}
