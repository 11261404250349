import { TimeSelection } from '../../../../../objects/core/time-selection/time-selection/time-selection';
import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { IdentityConfigConversation } from '../../../../../objects/identity/identity-config/identity-config-conversation/identity-config-conversation';
import { ConversationRequestSchema } from '../../generic/conversation-request/conversation-request-schema';
import { CalleeEntity } from '../../../../../objects/call/callee-entity/callee-entity';

export class ConversationRequestConfigureSchema extends ConversationRequestSchema {
  public static readonly conversationConfigId = 'conversationConfigId';

  public static readonly availableTimes = 'availableTimes';

  public static readonly inactiveAfterDuration = 'inactiveAfterDuration';

  public static readonly closedAfterDuration = 'closedAfterDuration';

  public static readonly pIIRetentionDuration = 'pIIRetentionDuration';

  public static readonly communicationWorkflowId = 'communicationWorkflowId';

  public static readonly redirectURL = 'redirectURL';

  public static readonly waitingRoomConfigEnabled = 'waitingRoomConfigEnabled';

  public static readonly identityConfig = 'identityConfig';
  
  public static readonly serviceProviders = 'serviceProviders';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends ConversationRequestSchema.Defaults {
    public static readonly conversationConfigId = undefined;

    public static readonly availableTimes = undefined;

    public static readonly inactiveAfterDuration = undefined;

    public static readonly closedAfterDuration = undefined;

    public static readonly pIIRetentionDuration = undefined;

    public static readonly communicationWorkflowId = undefined;

    public static readonly redirectURL = undefined;

    public static readonly waitingRoomConfigEnabled = undefined;

    public static readonly identityConfig = undefined;
    
    public static readonly serviceProviders = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationRequestConfigureSchema.conversationConfigId] = SchemaField.autoGeneratedDocId(
      ConversationRequestConfigureSchema.Defaults.conversationConfigId,
    ).required();
    parameters[ConversationRequestConfigureSchema.availableTimes] = SchemaField.obj(
      TimeSelection,
      ConversationRequestConfigureSchema.Defaults.availableTimes,
    );

    parameters[ConversationRequestConfigureSchema.inactiveAfterDuration] = SchemaField.isoDuration(
      ConversationRequestConfigureSchema.Defaults.inactiveAfterDuration,
    ).required();
    parameters[ConversationRequestConfigureSchema.closedAfterDuration] = SchemaField.isoDuration(
      ConversationRequestConfigureSchema.Defaults.closedAfterDuration,
    ).required();
    parameters[ConversationRequestConfigureSchema.pIIRetentionDuration] = SchemaField.isoDuration(
      ConversationRequestConfigureSchema.Defaults.pIIRetentionDuration,
    ).required();

    parameters[ConversationRequestConfigureSchema.communicationWorkflowId] = SchemaField.autoGeneratedDocId(
      ConversationRequestConfigureSchema.Defaults.communicationWorkflowId,
    ).optional();
    parameters[ConversationRequestConfigureSchema.redirectURL] = SchemaField.uri(
      ConversationRequestConfigureSchema.Defaults.redirectURL,
    ).optional();
    parameters[ConversationRequestConfigureSchema.waitingRoomConfigEnabled] = SchemaField.boolean(
      ConversationRequestConfigureSchema.Defaults.waitingRoomConfigEnabled,
    ).required();
    parameters[ConversationRequestConfigureSchema.identityConfig] = SchemaField.obj(
      IdentityConfigConversation,
      ConversationRequestConfigureSchema.Defaults.identityConfig,
    );
    parameters[ConversationRequestConfigureSchema.serviceProviders] = SchemaField.shortObjArray(
      CalleeEntity,
      ConversationRequestConfigureSchema.Defaults.serviceProviders,
    );
    /* eslint-enable */

    return parameters;
  }
}
