import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationDevice } from '../communication-device/communication-device';

export class CommunicationDevicesSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly devices = 'devices';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly devices = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is a collection of communication devices
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationDevicesSchema.devices] = SchemaField.arrayOfType(
      SchemaField.obj(CommunicationDevice, undefined),
      CommunicationDevicesSchema.Defaults.devices
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
