import Joi, { type AnySchema } from 'joi';

import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetOrderByExplicitSchema } from '../../type/communication-workflow-target-order-by-explicit/communication-workflow-target-order-by-explicit-schema';
import { CommunicationWorkflowTargetOrderByLongestIdleSchema } from '../../type/communication-workflow-target-order-by-longest-idle/communication-workflow-target-order-by-longest-idle-schema';

export class AnyCommunicationWorkflowTargetOrderBySchema extends SerializableObjectSchema {
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationWorkflowTargetOrderBy';

  private static readonly computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationWorkflowTargetOrderByExplicitSchema().getJoiSchema(),
        new CommunicationWorkflowTargetOrderByLongestIdleSchema().getJoiSchema(),
      ],
    )
    .id(this.SCHEMA_ID);

  public static getSchemaId(): string {
    return `#${AnyCommunicationWorkflowTargetOrderBySchema.SCHEMA_ID}`;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyCommunicationWorkflowTargetOrderBySchema.computedSchema;
  }
}
