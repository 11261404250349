import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { translate, TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';

import { UserResponseUpdateUserError } from '@pwp-common';

import { mustMatch } from '../../../../common/validators/must-match/must-match';
import { UserEndpointService } from '../../../../services/user/user-endpoint/user-endpoint.service';
import { FormFieldHelpComponent } from '../../../form/form-field-help/form-field-help.component';
import { SettingsModule } from '../../../generic/settings/settings.module';

import { ChangeOtherUserEmailDialogInput } from './interfaces';

@Component({
  selector: 'app-change-other-user-email',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    FormFieldHelpComponent,
    MessagesModule,
    TranslocoModule,
    LetModule,
    MatIconModule,
    CardModule,
    StepsModule,
    ToastModule,
    SettingsModule,
  ],
  templateUrl: './change-other-user-email.component.html',
  styleUrls: ['./change-other-user-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class ChangeOtherUserEmailComponent {
  ///////////////////////////////////////////////////////////////////////
  // Services
  ///////////////////////////////////////////////////////////////////////

  private readonly userEndpoint = inject(UserEndpointService);

  private readonly dynamicDialogRef = inject(DynamicDialogRef);

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly messageService = inject(MessageService);

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  private readonly config: DynamicDialogConfig<ChangeOtherUserEmailDialogInput> = inject(
    DynamicDialogConfig<ChangeOtherUserEmailDialogInput>,
  );

  public readonly form = this.fb.group(
    {
      newEmail: this.fb.control('', [Validators.minLength(10), Validators.email]),
      newEmailConfirmation: this.fb.control('', [Validators.required, Validators.email]),
    },
    {
      validators: [mustMatch('newEmail', 'newEmailConfirmation')],
    },
  );
  ///////////////////////////////////////////////////////////////////////
  // Error
  ///////////////////////////////////////////////////////////////////////

  private handleError() {
    this.messageService.add({
      severity: 'error',
      summary: translate(`change-other-user-email.errors.errorTitle`),
      detail: translate(`change-other-user-email.errors.${UserResponseUpdateUserError.unableToUpdateUser}`),
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Save
  ///////////////////////////////////////////////////////////////////////

  // eslint-disable-next-line no-underscore-dangle
  public changeEmail = async () => this._changeEmail();

  // eslint-disable-next-line no-underscore-dangle
  private async _changeEmail(): Promise<void> {
    const { userId } = this.config.data;
    const { newEmail } = this.form.value;

    try {
      console.log(this.userEndpoint, userId, newEmail);
      const result = await this.userEndpoint.updateUser({ userId, email: newEmail });
      if (result.getError()) {
        this.handleError();
      }
    } catch (error) {
      this.handleError();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Cancel
  ///////////////////////////////////////////////////////////////////////

  public cancel = () => {
    // eslint-disable-next-line no-underscore-dangle
    this._cancel();
  };

  // eslint-disable-next-line no-underscore-dangle
  private _cancel() {
    this.dynamicDialogRef.close();
  }

  ///////////////////////////////////////////////////////////////////////
  // Valid
  ///////////////////////////////////////////////////////////////////////

  public isValid = () =>
    // eslint-disable-next-line no-underscore-dangle
    this._isValid();

  // eslint-disable-next-line no-underscore-dangle
  private _isValid() {
    return this.form?.valid ?? false;
  }

  ///////////////////////////////////////////////////////////////////////
  // Reset
  ///////////////////////////////////////////////////////////////////////

  public resetForm = () =>
    // eslint-disable-next-line no-underscore-dangle
    this._resetForm();

  // eslint-disable-next-line no-underscore-dangle
  private _resetForm() {
    this.form.reset({ newEmail: '', newEmailConfirmation: '' });
    this.dynamicDialogRef.close();
  }
}
