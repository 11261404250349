import { SchemaField } from "../../../../generic/serialization/schema-field";
import { CommunicationTaskSchema } from "../../generic/communication-task/communication-task-schema";

export class CommunicationTaskCallSchema extends CommunicationTaskSchema {
  public static readonly callSid = "callSid";
  public static readonly callListId = "callListId";

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly orgId = undefined;
    public static readonly sessionId = undefined;
    public static readonly callSid = undefined;
    public static readonly callListId = undefined;
    public static readonly taskQueueAttributes = new Map();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import("joi").AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationTaskCallSchema.callSid] = SchemaField.string(
      CommunicationTaskCallSchema.Defaults.callSid
    );
    parameters[CommunicationTaskCallSchema.callListId] = SchemaField.autoGeneratedDocId(
      CommunicationTaskCallSchema.Defaults.callListId
    );
    /* eslint-enable */

    return parameters;
  }
}
